<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main">
      <s-fileConfigDialog v-if="showEditDialog" v-model="showEditDialog" :id="idEdit" />
    </portal>
    <a-table-f-api ref="table" :api="url" :model="getModel()" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      //model: this.$store.getters["config/get"].models.info.list,
      model: [
        {
          name: "id",
          title: "#",
          type: "id",
          sortable: true,
          width: 50,
        },
        {
          name: "createdon",
          title: "Дата",
          type: "datetime",
          sortable: true,
          width: 120,
        },

        {
          name: "createdby_id",
          title: "Автор",
          type: "select",
          dir: "user",
          sortable: true,
          width: 180,
        },
        {
          name: "name",
          title: "Название",
          type: "string",
          sortable: true,
          width: 180,
        },
        {
          name: "doc_parent_name",
          title: "Документ",
          type: "string",
          filter: { name: "uploads_docs.parent_name", type: "string" },
          sortable: true,
          width: 180,
        },
      ],
      url: "/uploads",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters1: {
          "uploads_docs.parent_name": "accounting_doc_building_sell",
        },
      },
    };
  },
  created() {
    this.$root.title = "Загруженные файлы";
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    getModel() {
      let model = [];
      let f_doc = [
        { text: "Без документа", value: { condition: " is ", value: "null" } },
        { text: "Договор продажи", value: "accounting_doc_building_sell" },
        { text: "Договор покупки", value: "accounting_doc_building_buy" },
      ];
      model = [
        { name: "id", title: "#", type: "id", sortable: true, width: 50 },
        { name: "createdon", title: "Дата", type: "datetime", sortable: true, width: 120 },
        { name: "createdby_id", title: "Автор", type: "select", dir: "user", sortable: true, width: 180 },
        { name: "name", title: "Название", type: "string", sortable: true, width: 180 },
        {
          name: "doc_parent_name",
          title: "Документ",
          type: "string",
          filter: { name: "uploads_docs.parent_name", type: "select", options: f_doc },
          sortable: true,
          width: 180,
        },
      ];
      return model;
    },
  },
};
</script>
